import React from 'react';
import { compose } from 'recompose';

import Layout from '../components/layout';
import Devices from '../components/Devices';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import { Link } from 'gatsby';
import * as ROUTES from '../constants/routes';
import styles from './devices-list.module.scss';

const DevicesListBase = () => (
    <div className="page-content header-clear-medium content" >
        <Link to={ROUTES.ADD_DEVICE} className={`bg-highlight shadow-m ${styles.addbtn}`} ><i className="fa fa-plus"></i></Link>
        <Devices/>
    </div>
);

const condition = authUser => !!authUser;

const DevicesList = compose(
  withEmailVerification,
  withAuthorization(condition),
)(DevicesListBase);

export default () => (
  <Layout>
    <DevicesList />
  </Layout>
);
