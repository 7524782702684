import React from 'react';

import DeviceItem from './DeviceItem';

const DevicesList = ({
  authUser,
  devices
}) => (
    <div className="row mb-0">
        {devices.map((device, index) => (
            <DeviceItem
                key={device.key}
                authUser={authUser}
                device={device}
                col={index%2}
            />
        ))}
   </div>
);

export default DevicesList;
