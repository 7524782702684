import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import DeviceList from './DeviceList';

class DevicesBase extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      devices: [],
      limit: 5,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.onListenForDevices();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForDevices = () => {
    this.props.firebase.getDevicesOfUser(this.props.authUser.uid).then( devices =>{
      const devicesList = Object.values(devices)
      if(devicesList.length > 0)
        this.setState({ devices:devicesList, loading: false });
      else this.setState({ devices: null, loading: false });
    })
  };



  render() {
    const { text, devices, loading } = this.state;
    const { firebase , authUser } = this.props;
    return (
          <div>
            {loading && <div id="preloader"><div className="spinner-border color-highlight" role="status"></div></div>}

            {devices && (
              <DeviceList
                authUser={authUser}
                devices={devices}
              />
            )}

            {!devices && <div>There are no devices ...</div>}

          </div>
    );
  }
}

class Devices extends Component {

  constructor(props) {
    super(props);
  }
  render() {
    return (
      <AuthUserContext.Consumer>
        {authUser => <DevicesBase authUser={authUser} firebase={this.props.firebase}/> }
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Devices);
